module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VolleyStats.co.uk","short_name":"VolleyStats","start_url":"/","background_color":"#15254C","theme_color":"#15254C","display":"minimal-ui","icon":"src/assets/images/website-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-129385862-1","head":false,"anonymize":false,"respectDNT":true,"exclude":[],"cookieDomain":"volleystats.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
